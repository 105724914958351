import { useQuery, UseQueryOptions } from "react-query";
import { QUESTIONNAIRE_CONFIG } from "../config/questionnaire.config";
import { API_CONFIG } from "../config/api.config";

export type ResponseCreated = {
  response_uuid: string;
};

type GetResponseUuidQueryOptions = Omit<
  UseQueryOptions<ResponseCreated, unknown, ResponseCreated, string[]>,
  "queryKey" | "queryFn"
>;

export const useGetResponseUuidQuery = (
  options?: GetResponseUuidQueryOptions
) => {
  return useQuery({
    ...options,
    queryKey: ["generateResponseUuid", QUESTIONNAIRE_CONFIG.QUESTIONNAIRE_ID],
    queryFn: async () => {
      // const url = new URL(
      //   `questionnaire/${QUESTIONNAIRE_CONFIG.QUESTIONNAIRE_ID}/response`,
      //   API_CONFIG.BACKEND_URL
      // );
      const url = new URL(
        `responses/questionnaire/${QUESTIONNAIRE_CONFIG.QUESTIONNAIRE_ID}`,
        API_CONFIG.NEST_API_URL
      );

      const responseStarted = await fetch(url.href, {
        method: "get",
        headers: {
          accept: "application/json",
        },
      });
      const created: unknown = (await responseStarted.json()) as unknown;

      if (!isResponseCreated(created)) {
        throw Error("something failed on backend");
      }

      return created;
    },
  });
};

function isResponseCreated(created: any): created is ResponseCreated {
  const key: keyof ResponseCreated = "response_uuid";

  return created.hasOwnProperty(key);
}
