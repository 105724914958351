import React, { useState } from "react";
import Popup from "../ProgressSaved";
import SurveyComponent from "../SurveyComponent";
import { useTracking } from "../hooks/useTracking";

type SurveyHandlerProps = {
  onNewResponseUuid: (uuid: string) => void;
};

const SurveyHandler = (props: SurveyHandlerProps) => {
  const tracking = useTracking(props.onNewResponseUuid);
  const [showPartialPopup, setShowPartialPopup] = useState(
    tracking.isInProgress()
  );

  return (
    <div>
      {showPartialPopup && (
        <Popup
          onReload={() => {
            tracking.askForNewUuid();
            setShowPartialPopup(false);
          }}
          onContinue={() => setShowPartialPopup(false)}
        ></Popup>
      )}
      <SurveyComponent onNewResponseUuid={props.onNewResponseUuid} />
    </div>
  );
};

export default SurveyHandler;
