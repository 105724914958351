import { env } from "../../config/env";

export type AuthConfig = {
  AUTH_ISSUER: string;
  AUTH_AUDIENCE: string;
  AUTH_CLIENT_ID: string;
};

export const AUTH_CONFIG: AuthConfig = {
  AUTH_ISSUER: env("AUTH_ISSUER") ?? "ISSUER_NOT_PROVIDED",
  AUTH_AUDIENCE: env("AUTH_AUDIENCE") ?? "AUDIENCE_NOT_PROVIDED",
  AUTH_CLIENT_ID: env("AUTH_CLIENT_ID") ?? "CLIENT_ID_NOT_PROVIDED",
};
