import { useMutation, UseMutationOptions } from "react-query";
import { API_CONFIG } from "../config/api.config";

export type Email = {
  response_guid: string;
  email: string;
};

type SendReportParamsOptions = Omit<
  UseMutationOptions<void, unknown, Email>,
  "mutationFn"
>;

export const useSendReportParamsMutation = (
  options?: SendReportParamsOptions
) => {
  return useMutation({
    ...options,
    mutationFn: async (email: Email) => {
      const url = new URL(`questionnaire`, API_CONFIG.BACKEND_URL);
      const response = await fetch(url.href, {
        method: "put",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(email),
      });
      if (!response.ok) {
        throw new Error("Dogabane");
      }
      return;
    },
  });
};
