import React from "react";
import { useNavigate } from "react-router-dom";
import "./LandingPage.css";
import { Button } from "../components/atoms/buttons/Button";
import { ContentCenterBox } from "../components/atoms/layouts/ContentCenterBox";

export default function LandingPage() {
  const navigate = useNavigate();

  return (
    <>
      <div className="landing-page">
        <h1 className="heading--center">Welcome</h1>
        <p>
          Welcome to our Pacellico Self Assessment Tool, an online Self-Assessment tool
          developed by our team of experts in Supply Chain Strategy. Our tool is
          designed to provide you with valuable insights and recommendations
          based on your inputs to optimize your supply chain performance.
        </p>
        <p>
          The assessment takes only 10-15 minutes to complete. Once you finish,
          you will receive a report containing a spider graph, highlighting your
          5 recommendations to improve your supply chain performance.
        </p>
      </div>
      <div className='button-container'>
      <ContentCenterBox>
        <Button
          size="xl"
          onClick={() => {
            navigate("/survey");
          }}
        >
          Let's start {">"}
        </Button>
      </ContentCenterBox>
      </div>
    </>
  );
}
