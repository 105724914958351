/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type RoleDto = {
    role: RoleDto.role;
};
export namespace RoleDto {
    export enum role {
        GLOBAL_ADMINISTRATOR = 'global administrator',
        COMPANY_ADMINISTRATOR = 'company administrator',
        POWER_BI_EXPORTER = 'Power BI exporter',
    }
}

