import { ICache } from './ICache';

type WrappedCacheEntry<T> = {
  body: T;
  expiresAt: number;
};

export const DEFAULT_NOW_PROVIDER = () => Date.now();

const DEFAULT_EXPIRY_ADJUSTMENT_SECONDS = 0;

export type Seconds = number;

export class CacheManager {
  private nowProvider: () => number | Promise<number>;

  constructor(private cache: ICache, nowProvider?: () => number | Promise<number>) {
    this.nowProvider = nowProvider || DEFAULT_NOW_PROVIDER;
  }

  async get<T>(
    cacheKey: string,
    expiryAdjustmentSeconds: Seconds = DEFAULT_EXPIRY_ADJUSTMENT_SECONDS
  ): Promise<T | undefined> {
    const cacheWrappedEntry = await this.cache.get<WrappedCacheEntry<T>>(cacheKey);

    // If we still don't have an entry, exit.
    if (!cacheWrappedEntry) {
      return;
    }

    const now = await this.nowProvider();
    const nowSeconds = Math.floor(now / 1000);

    if (cacheWrappedEntry.expiresAt - expiryAdjustmentSeconds < nowSeconds) {
      await this.cache.remove(cacheKey);

      return;
    }

    return cacheWrappedEntry.body;
  }

  async set<T>(key: string, value: T, expiresIn: Seconds): Promise<void> {
    const wrappedEntry = await this.wrapCacheEntry<T>(value, expiresIn);

    await this.cache.set<WrappedCacheEntry<T>>(key, wrappedEntry);
  }

  private async wrapCacheEntry<T>(value: T, expiresIn: Seconds): Promise<WrappedCacheEntry<T>> {
    const now = await this.nowProvider();
    const expiresInTime = Math.floor(now / 1000) + expiresIn;

    return {
      body: value,
      expiresAt: expiresInTime,
    };
  }

  public async clear(): Promise<void> {
    return await this.cache.clear();
  }
}
