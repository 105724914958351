import { ICache } from './ICache';

export const InMemoryCache = (): ICache => {
  const cache: Record<string, unknown> = {};

  return {
    set: async <T>(key: string, entry: T) => {
      cache[key] = entry;

      return Promise.resolve();
    },

    get: async <T>(key: string) => {
      const cacheEntry = cache[key] as T | undefined;

      return Promise.resolve(cacheEntry);
    },

    remove: async (key: string) => {
      delete cache[key];

      return Promise.resolve();
    },

    clear: async () => {
      Object.keys(cache).forEach((key) => {
        delete cache[key];
      });
    },
  };
};
