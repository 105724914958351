import React, { useEffect, useRef, useState } from "react";
import {
  AuthContext,
  AuthContextType,
  DEFAULT_AUTH_CONTEXT,
  User,
} from "./AuthContext";
import * as oauth from "oauth4webapi";
import { AuthorizationServer, OpenIDTokenEndpointResponse } from "oauth4webapi";
import { AuthFlowCache } from "./cache/AuthFlowCache";
import { TokenCacheManager } from "./TokenCacheManager";
import { ITokenCache, Token } from "./cache/ITokenCache";

type OAuthConfig = {
  issuer: URL;
  audience: string;
  clientId: string;
  redirectUri: string;

  beforeRedirect: () => void;
};

type AuthProviderType = {
  config: OAuthConfig;
  children?: React.ReactNode;
};

type AuthenticationState = {
  verifier: string;
  challenge: string;
  state: string;
  redirectUri: string;
  clientId: string;
};

type LoggedInUser =
  | {
      accessToken: string;
      refreshToken?: string;
      idToken?: string;
      expiresIn?: number;
      user: User;
    }
  | undefined;

const CODE_CHALLENGE_METHOD = "S256";
const DEFAULT_TOKEN_EXPIRY = 10;

export const AuthProvider = (props: AuthProviderType) => {
  const [authContext, setAuthContext] =
    useState<AuthContextType>(DEFAULT_AUTH_CONTEXT);
  const [cache] = useState(
    TokenCacheManager({ ticket: props.config.clientId })
  );
  const isSecondCall = useRef(false);

  useEffect(() => {
    if (!isSecondCall.current) {
      isSecondCall.current = true;
      login(props.config, cache).then((newContext) => {
        setAuthContext(newContext);
      });
    }
  });

  return (
    <AuthContext.Provider value={authContext}>
      {authContext.isAuthenticated && props.children}
      {!authContext.isAuthenticated && <div>authenticating...</div>}
    </AuthContext.Provider>
  );
};

const login = async (
  config: OAuthConfig,
  tokenCache: ITokenCache
): Promise<AuthContextType> => {
  const authServer = await oauth
    .discoveryRequest(config.issuer)
    .then((response) =>
      oauth.processDiscoveryResponse(config.issuer, response)
    );

  const authCache = AuthFlowCache<AuthenticationState>({
    ticket: config.clientId,
  });

  if (!authCache.read()) {
    // attempt for authentication not happening, so start one
    await authenticate(config, authServer);

    //redirect should start, so I guess this should be canceled
    return DEFAULT_AUTH_CONTEXT;
  }

  //something in cache we need to check if it is correct
  const loggedInUser = await verifyLoginRequest(config, authServer);
  if (!loggedInUser) {
    console.log("user not logged in reauthenticating");
    //failed to get user data to progress, so try to authenticate again
    await authenticate(config, authServer);

    //redirect should be happening, so I guess this should be canceled and not executed
    return DEFAULT_AUTH_CONTEXT;
  }

  await tokenCache.write(
    Token.AccessToken,
    loggedInUser.accessToken,
    loggedInUser.expiresIn ?? DEFAULT_TOKEN_EXPIRY
  );

  if (loggedInUser.refreshToken) {
    await tokenCache.write(
      Token.RefreshToken,
      loggedInUser.refreshToken,
      Infinity
    );
  }

  return {
    user: loggedInUser.user,
    isAuthenticated: true,
    getToken: async () => provideToken(config, authServer, tokenCache),
    logout: async () => {
      const url = new URL(authServer.end_session_endpoint!);
      url.searchParams.append("iss", config.issuer.href);
      url.searchParams.append("sid", loggedInUser.user.sid);
      // url.searchParams.append('id_token_hint', idToken);

      await tokenCache.clear();
      window.location.replace(url);

      //else authenticate again
      // await authenticate(config, authServer);
    },
  };
};

const authenticate = async (
  config: OAuthConfig,
  authServer: oauth.AuthorizationServer
) => {
  if (authServer.code_challenge_methods_supported?.includes("S256") !== true) {
    throw new Error("S256 PKCE no supported by issuer");
  }

  const codeVerifier = oauth.generateRandomCodeVerifier();
  const codeChallenge = await oauth.calculatePKCECodeChallenge(codeVerifier);
  const codeState = oauth.generateRandomState();

  {
    const authCache = AuthFlowCache<AuthenticationState>({
      ticket: config.clientId,
    });

    authCache.write({
      verifier: codeVerifier,
      challenge: codeChallenge,
      state: codeState,
      redirectUri: config.redirectUri,
      clientId: config.clientId,
    });
  }

  config.beforeRedirect();

  {
    const authorizationUrl = new URL(authServer.authorization_endpoint!);
    authorizationUrl.searchParams.set("client_id", config.clientId);
    authorizationUrl.searchParams.set("code_challenge", codeChallenge);
    authorizationUrl.searchParams.set(
      "code_challenge_method",
      CODE_CHALLENGE_METHOD
    );
    authorizationUrl.searchParams.set("redirect_uri", config.redirectUri);
    authorizationUrl.searchParams.set("response_type", "code");
    authorizationUrl.searchParams.set("scope", "openid profile email offline");
    authorizationUrl.searchParams.set("state", codeState);
    authorizationUrl.searchParams.set("audience", config.audience);

    window.location.replace(authorizationUrl.href);
  }
};

const verifyLoginRequest = async (
  config: OAuthConfig,
  authServer: oauth.AuthorizationServer
): Promise<LoggedInUser | undefined> => {
  const currentUrl: URL = new URL(window.location.href);

  const client: oauth.Client = {
    client_id: config.clientId,
    token_endpoint_auth_method: "none",
  };

  const authCache = AuthFlowCache<AuthenticationState>({
    ticket: config.clientId,
  });
  const authInProgress = authCache.read()!;

  let params: URLSearchParams;
  try {
    const paramsToTest = oauth.validateAuthResponse(
      authServer,
      client,
      currentUrl,
      authInProgress.state
    );
    if (oauth.isOAuth2Error(paramsToTest)) {
      return undefined;
    }
    params = paramsToTest;
  } catch {
    return undefined;
  }

  const response = await oauth.authorizationCodeGrantRequest(
    authServer,
    client,
    params,
    authInProgress.redirectUri,
    authInProgress.verifier
  );

  const challenges = oauth.parseWwwAuthenticateChallenges(response);
  if (challenges) {
    return undefined;
  }

  let result: OpenIDTokenEndpointResponse;
  try {
    const resultToTest = await oauth.processAuthorizationCodeOpenIDResponse(
      authServer,
      client,
      response
    );
    if (oauth.isOAuth2Error(resultToTest)) {
      return undefined;
    }

    result = resultToTest;
  } catch {
    return undefined;
  }

  const claims = oauth.getValidatedIdTokenClaims(result);

  //user successfully loggedIn clear auth cache
  authCache.clear();

  return {
    accessToken: result.access_token,
    refreshToken: result.refresh_token,
    idToken: result.id_token,
    expiresIn: result.expires_in,
    user: {
      id: claims.sub,
      email: String(claims["email"]!),
      firstName: String(claims["given_name"]),
      lastName: String(claims["family_name"]),
      sid: String(claims["sid"]),
    },
  };
};

const provideToken = async (
  config: OAuthConfig,
  authServer: AuthorizationServer,
  tokenCache: ITokenCache
) => {
  const accessToken = await tokenCache.read(Token.AccessToken);
  if (accessToken) {
    return accessToken;
  }

  const refreshToken = await tokenCache.read(Token.RefreshToken);
  if (refreshToken) {
    const client: oauth.Client = {
      client_id: config.clientId,
      token_endpoint_auth_method: "none",
    };

    const refreshTokenResponse = await oauth.refreshTokenGrantRequest(
      authServer,
      client,
      refreshToken
    );

    const tokens = await oauth.processRefreshTokenResponse(
      authServer,
      client,
      refreshTokenResponse
    );

    if (!oauth.isOAuth2Error(tokens)) {
      await tokenCache.write(
        Token.AccessToken,
        tokens.access_token,
        tokens.expires_in ?? DEFAULT_TOKEN_EXPIRY
      );
      return tokens.access_token;
    }
  }

  await authenticate(config, authServer);

  return "you should never be here";
};
