import { env } from "./env";

export type QuestionnaireConfig = {
  QUESTIONNAIRE_ID: string;
};

export const QUESTIONNAIRE_CONFIG: QuestionnaireConfig = {
  QUESTIONNAIRE_ID: env("QUESTIONNAIRE_ID") ?? questionnaireIdDefault(),
};

function questionnaireIdDefault(): string {
  throw Error("questionnarire id not provided");
}
