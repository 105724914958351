/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PermissionDto = {
    permission: PermissionDto.permission;
};
export namespace PermissionDto {
    export enum permission {
        READ_TEST = 'read:test',
        CREATE_USERS = 'create:users',
        READ_USERS = 'read:users',
        UPDATE_USERS = 'update:users',
        DELETE_USERS = 'delete:users',
        EXPORT_USERS = 'export:users',
        CREATE_ORGS = 'create:orgs',
        READ_ORGS = 'read:orgs',
        UPDATE_ORGS = 'update:orgs',
        DELETE_ORGS = 'delete:orgs',
        CREATE_PROJECTS = 'create:projects',
        READ_PROJECTS = 'read:projects',
        UPDATE_PROJECTS = 'update:projects',
        DELETE_PROJECTS = 'delete:projects',
        CREATE_SUB_PROJECTS = 'create:sub_projects',
        READ_SUB_PROJECTS = 'read:sub_projects',
        UPDATE_SUB_PROJECTS = 'update:sub_projects',
        DELETE_SUB_PROJECTS = 'delete:sub_projects',
        CREATE_OWN_ORG_MEMBERS = 'create:own_org_members',
        READ_OWN_ORG_MEMBERS = 'read:own_org_members',
        UPDATE_OWN_ORG_MEMBERS = 'update:own_org_members',
        DELETE_OWN_ORG_MEMBERS = 'delete:own_org_members',
        EXPORT_PAGES = 'export:pages',
        EXPORT_CHAPTERS = 'export:chapters',
        EXPORT_PROJECTS = 'export:projects',
        EXPORT_QUESTIONNAIRES = 'export:questionnaires',
        EXPORT_QUESTIONS = 'export:questions',
        EXPORT_ORGS = 'export:orgs',
        EXPORT_RESPONSES = 'export:responses',
        EXPORT_SCORED_ITEMS = 'export:scored_items',
        EXPORT_ANSWERS = 'export:answers',
        EXPORT_RESPONSE_ANSWERS = 'export:response_answers',
        EXPORT_RESPONSE_SCORES = 'export:response_scores',
        EXPORT_SCORE_RAGES = 'export:score_rages',
    }
}

