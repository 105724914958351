import {
  killProgress,
  isInProgress,
  searchForUuid,
  saveProgress,
  saveUuid,
  getProgress,
  Progress,
} from "../utils/progressTracking";
import { useGetResponseUuidQuery } from "../repositories/GetResponseUuidQuery";

export const useTracking = (onNewResponseUuid: (uuid: string) => void) => {
  const alreadyHaveUuid = !!searchForUuid();

  const getResponseUuidQuery = useGetResponseUuidQuery({
    refetchOnWindowFocus: false,
    enabled: !alreadyHaveUuid,
  });

  if (
    !alreadyHaveUuid &&
    !getResponseUuidQuery.isLoading &&
    getResponseUuidQuery.data?.response_uuid
  ) {
    saveUuid(getResponseUuidQuery.data.response_uuid);
    onNewResponseUuid(getResponseUuidQuery.data.response_uuid);
  }

  const uuid = alreadyHaveUuid
    ? searchForUuid()!
    : getResponseUuidQuery.data?.response_uuid!;

  return {
    isLoading: alreadyHaveUuid ? false : getResponseUuidQuery.isLoading,
    uuid: uuid,
    getProgress: () => getProgress(uuid),
    saveProgress: (progress: Progress) => saveProgress(uuid, progress),
    killProgress: killProgress,
    isInProgress: isInProgress,

    askForNewUuid: () => {
      killProgress();
      getResponseUuidQuery.refetch().catch((e) => {
        throw e;
      });
    },
  };
};
