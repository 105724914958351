import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./components/pages/Home";
import LandingPage from "./routes/LandingPage";
import Authenticate from "./routes/Authenticate";
import SurveyHandler from "./routes/SurveyHandler";
import Complete from "./routes/Complete";
import EmailComponent from "./EmailComponent";
import { ErrorPage } from "./components/pages/ErrorPage";
import React, { useState } from "react";
import { AUTH_CONFIG } from "./plugins/auth/auth.config";
import { CallbackAPI } from "./auth-redirect/CallbackAPI";
import { AuthProvider } from "./plugins/pacellico-auth/context/AuthProvider";
import useResponseGet from "./repositories/useResponseGet";
import { RedirectCallback } from "./auth-redirect/RedirectCallback";
import { saveUuid } from "./utils/progressTracking";

export default function App() {
  const [responseUuid, setResponseUuid] = useState<string | undefined>(
    undefined
  );

  const responseUuidIdentified = (uuid: string) => {
    saveUuid(uuid);
    setResponseUuid(uuid);
  };

  const getResponse = useResponseGet(responseUuid);

  return (
    <BrowserRouter>
      {!getResponse.data || getResponse.data.questionnaire.isAnonymous ? (
        <Routes>
          <Route path="/" element={<Home />}>
            <Route path="/" element={<LandingPage />} />
            <Route
              path="/response/:uuid"
              element={
                <Authenticate onNewResponseUuid={responseUuidIdentified} />
              }
            />
            <Route
              path="/survey"
              index
              element={
                <SurveyHandler onNewResponseUuid={responseUuidIdentified} />
              }
            />{" "}
            <Route path="/callback" index element={<RedirectCallback />} />
            <Route path="/survey/complete/:guid" element={<Complete />} />
            <Route
              path="/survey/complete/:guid/report"
              element={<EmailComponent />}
            />
            <Route path="*" element={<ErrorPage />} />
          </Route>
        </Routes>
      ) : (
        <AuthProvider
          config={{
            clientId: AUTH_CONFIG.AUTH_CLIENT_ID,
            audience: AUTH_CONFIG.AUTH_AUDIENCE,
            issuer: new URL(AUTH_CONFIG.AUTH_ISSUER),
            redirectUri: new URL("/callback", window.location.origin).href,
            beforeRedirect: () => CallbackAPI.remember(),
          }}
        >
          <Route path="/" element={<Home />}>
            <Route path="/" element={<LandingPage />} />
            <Route
              path="/response/:uuid"
              element={
                <Authenticate onNewResponseUuid={responseUuidIdentified} />
              }
            />
            <Route
              path="/survey"
              index
              element={
                <SurveyHandler onNewResponseUuid={responseUuidIdentified} />
              }
            />
            <Route path="/callback" index element={<RedirectCallback />} />
            <Route path="/survey/complete/:guid" element={<Complete />} />
            <Route
              path="/survey/complete/:guid/report"
              element={<EmailComponent />}
            />
            <Route path="*" element={<ErrorPage />} />
          </Route>
        </AuthProvider>
      )}
    </BrowserRouter>
  );
}
