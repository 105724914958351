import { useQuery, UseQueryOptions } from "react-query";
import { API_CONFIG } from "../config/api.config";

export type Questionnaire = {
  questionnaire_guid: string;
};

type SpiderScores = {
  score: number;
  score_item_id: number;
  score_item_text: string;
};

type QuestionnaireCreated = {
  recommendations: string[];
  spider_recommendations: string[];
  spider_scores: SpiderScores[];
};

type QuestionareCreatedData = QuestionnaireCreated | null;

type SendQuestionnaireOptions = UseQueryOptions<
  QuestionareCreatedData,
  unknown,
  QuestionareCreatedData,
  string[]
>;

export const useSendQuestionnaireQuery = (
  guid: string,
  options?: SendQuestionnaireOptions
) => {
  return useQuery<QuestionareCreatedData>(
    ["evaluate questionnaire", guid],
    async () => {
      // const url = new URL(
      //   `questionnaire/response/${guid}/results`,
      //   API_CONFIG.BACKEND_URL
      // );
      const url = new URL(
        `response-scores/response/${guid}/summary`,
        API_CONFIG.NEST_API_URL
      );
      const responseObtained = await fetch(url.href, {
        method: "get",
        headers: {
          "content-type": "application/json",
        },
      });
      const created: unknown = (await responseObtained.json()) as unknown;
      if (!isResponseObtained(created)) {
        throw Error("Something went wrong while processing your answers");
      }
      return created;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

function isResponseObtained(created: any): created is QuestionnaireCreated {
  const response: keyof QuestionnaireCreated = "spider_scores";
  return created.hasOwnProperty(response);
}
