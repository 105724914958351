import React, { ReactNode } from "react";
import classNames from "classnames";

import "./Button.css";

type Color = "white" | "teal";

const ColorToClassname: Record<Color, string> = {
  teal: "pac__button--teal",
  white: "pac__button--white",
};

type Size = "s" | "xl" | "xxl";

const SizeToClassname: Record<Size, string> = {
  s: "pac__button--s",
  xl: "pac__button--xl",
  xxl: "pac__button--xxl",
};

type ButtonProps = {
  size?: Size;
  color?: Color;
  type?: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >["type"];
  onClick?: () => void;
  disabled?: boolean;
  children: ReactNode;
};

export const Button = ({
  children,
  onClick,
  color = "teal",
  size = "s",
  disabled,
  type,
}: ButtonProps) => {
  return (
    <button
      className={classNames(
        "pac__button",
        ColorToClassname[color],
        SizeToClassname[size]
      )}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
};
