import React from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  Chart,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import { Options } from "./SpiderComponentOptions"

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

Chart.defaults.color = "#000000";

const data = (labels: string[] | undefined, data: number[] | undefined) => ({
  labels: labels,
  datasets: [
    {
      label: "You",
      data,
      backgroundColor: "rgb(9, 110, 137,.5)",
      borderColor: "rgb(9, 110, 137)",
      borderWidth: 2,
      fill: true,
      color: "#000000",
    },
  ],
});


export type SpiderComponentProps = {
  evaluatedData: number[];
  labels: string[];
  options: Options
};

export function SpiderComponent({
  labels,
  evaluatedData,
  options
}: SpiderComponentProps) {
  return (
    <Radar
      className="spider-graph"
      id="spider-canvas"
      data={data(labels, evaluatedData)}
      options={options}
      
    />
  );
}

export function SpiderInvisible({
  labels,
  evaluatedData,
  options
}: SpiderComponentProps) {
  return (
    <Radar
      className="spider-graph"
      id="spider-invisible"
      data={data(labels, evaluatedData)}
      options={options}
      
    />
  );
}
