import { DefaultService } from "../openapi";
import { useQuery } from "react-query";

function getQueryKeyForResponse(responseUuid: string | undefined) {
  return responseUuid ? ["response", responseUuid] : ["responses"];
}

export default function useResponseGet(responseUuid: string | undefined) {
  const getResponse = async () => {
    if (responseUuid) {
      return DefaultService.responsesControllerGet(responseUuid);
    }
  };

  return useQuery({
    queryKey: getQueryKeyForResponse(responseUuid),
    queryFn: getResponse,
    enabled: Boolean(responseUuid),
  });
}
