const CALLBACK_SESSION_KEY = `pacellico-admin-redirectSession`;
export const CallbackAPI = {
  remember: () => {
    const url = new URL(window.location.href);
    url.search = '';
    console.log('storing into session:', url.search);

    sessionStorage.setItem(CALLBACK_SESSION_KEY, url.href);
  },
  recall: () => {
    return sessionStorage.getItem(CALLBACK_SESSION_KEY);
  },

  forget: () => {
    sessionStorage.removeItem(CALLBACK_SESSION_KEY);
  },
};
