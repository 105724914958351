import { CallbackAPI } from "./CallbackAPI";
import { Navigate } from "react-router-dom";
import React, { useEffect } from "react";

export const RedirectCallback = () => {
  console.log(
    "navigate to:",
    new URL(CallbackAPI.recall() ?? window.location.origin).pathname
  );

  useEffect(() => {
    return () => CallbackAPI.forget();
  }, []);

  return (
    <Navigate
      to={new URL(CallbackAPI.recall() ?? window.location.origin).pathname}
    />
  );
};
