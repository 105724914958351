import React from "react";
import "./ProgressSaved.css";
import { Button } from "./components/atoms/buttons/Button";

type PopupProps = {
  onContinue: () => void;
  onReload: () => void;
};

const Popup: React.FC<PopupProps> = ({ onContinue, onReload }) => {
  return (
    <>
      {<div className="partial-overlay"></div>}
      <div className="partial-popup">
        <h2>Welcome back!</h2>
        <p>
          It seems like you have progress stored in your browser, would you like
          to continue?
        </p>
        <div className="buttons-container">
          <Button color="white" size="xl" onClick={onReload}>
            Start Again
          </Button>
          <Button color="teal" size="xl" onClick={onContinue}>
            Continue
          </Button>
        </div>
      </div>
    </>
  );
};

export default Popup;
