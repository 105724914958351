import React, { useMemo } from "react";

import { SpiderComponent, SpiderInvisible } from "../SpiderComponent";
import { useNavigate, useParams } from "react-router-dom";
import { useSendQuestionnaireQuery } from "../repositories/EvaluateQuestionnaireQuery";
import { Button } from "../components/atoms/buttons/Button";
import { ContentCenterBox } from "../components/atoms/layouts/ContentCenterBox";

import "./Complete.css";
import CircularIndeterminate from "../components/atoms/loaders/LoaderComponent";
import { useSaveGraphMutation } from "../repositories/SaveGraphMutation";

import { getScreenBasedOptions } from '../SpiderComponentOptions'

const EMPTY_GUID = "badbad";
export default function Complete() {
  const navigate = useNavigate();
  const params = useParams<"guid">();
  const evaluateQuestionnaire = useSendQuestionnaireQuery(
    params.guid ?? EMPTY_GUID,
    {
      refetchOnWindowFocus: false,
    }
  );
  const saveGraph = useSaveGraphMutation();
  const SpiderData = useMemo(() => {
    if (evaluateQuestionnaire.data == null) {
      return {
        scores: [0],
        labels: [],
        recommendations: [],
        spiderRecommendations: [],
      };
    }
    const scores = evaluateQuestionnaire.data.spider_scores.map(
      (item) => item.score
    );
    const labels = evaluateQuestionnaire.data.spider_scores.map(
      (item) => item.score_item_text
    );
    const spiderRecommendations =
      evaluateQuestionnaire.data.spider_recommendations;
    const recommendations = evaluateQuestionnaire.data.recommendations;

    return { scores, labels, recommendations, spiderRecommendations };
  }, [evaluateQuestionnaire.data]);


  return (
    <>
    
      {evaluateQuestionnaire.isError ? (
        <div className="summary-body">
          {
            "Something went wrong while processing your scores, please reload the page, if the issue persists, contact us at support@pacellico.com"
          }
        </div>
      ) : (
        
        <div className="summary-body">
          
          <h2>Quick Summary Report</h2>
          <p className="font--bold">
            Thank you for completing the assessment. Our self-assessment tool
            was developed based on years of experience but also reflects the
            challenges businesses face today. Below is a brief summary of your
            results. If you would like a more detailed report, please let us
            know and we will be happy to send it to you. We hope this assessment
            provides valuable insights for you.
          </p>

          {evaluateQuestionnaire.isLoading && (
            <div className="survey--loader--spinner">
              <CircularIndeterminate />
              Calculating results!
            </div>
          )}

          {!evaluateQuestionnaire.isLoading &&
            evaluateQuestionnaire.data !== null && (
              <div className="summary-body__data">
                <div className="summary-body__data__graph">
                  {evaluateQuestionnaire.data ? (
                    <>
                      <SpiderComponent
                        evaluatedData={SpiderData.scores}
                        labels={SpiderData.labels}
                        options={getScreenBasedOptions()}
                      />
                      <SpiderInvisible
                        evaluatedData={SpiderData.scores}
                        labels={SpiderData.labels}
                        options={getScreenBasedOptions()}
                      />
                    </>
                  ) : null}
                </div>
                <div className="summary-body__data__text">
                  <div className="data__text--recommendations">
                    <h2>Top Recommendations</h2>
                    {!evaluateQuestionnaire.isLoading ? (
                      <div>
                        {SpiderData.recommendations.map(
                          (recommendation, index) => (
                            <p key={`${index}_recommendation`}>
                              {recommendation}
                            </p>
                          )
                        )}
                        {SpiderData.spiderRecommendations.map(
                          (recommendation, index) => (
                            <p key={`${index}_spiderRecommendation`}>
                              {recommendation}
                            </p>
                          )
                        )}
                      </div>
                    ) : (
                      <div>Loading Recommendations</div>
                    )}
                  </div>
                </div>
              </div>
            )}
          <ContentCenterBox>
            <Button
              size="xxl"
              onClick={() => {
                saveGraph
                  .mutateAsync({
                    response_guid: params.guid ?? EMPTY_GUID,
                    graph: getCanvasBase64(),
                  })
                  .catch((e) => {
                    throw e;
                  });
                navigate(`/survey/complete/${params.guid}/report`);
              }}
            >
              I would like to get the full report
            </Button>
          </ContentCenterBox>
        </div>
      )}
    </>
  );
}

function getCanvasBase64() {
  const canvas = document.getElementById(
    "spider-invisible"
  ) as HTMLCanvasElement;
  return canvas.toDataURL("image/png");
}
