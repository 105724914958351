import * as React from "react";
import "./BrandHeader.css";
export default function BrandHeader() {
  return (
    <div className="header">
      <p
        className="header--text"
        onClick={() => {
          window.location.href = "https://self.assessment.pacellico.com/";
        }}
      >
        Pacellico Self Assessment
      </p>
    </div>
  );
}
