export const isInProgress = (): boolean => {
  const uuid = searchForUuid();
  return Boolean(uuid && window.localStorage.getItem(uuid));
};

export const killProgress = () => {
  const uuid = searchForUuid();
  if (!uuid) {
    return;
  }
  window.localStorage.removeItem("uuid");
  window.localStorage.removeItem(uuid);
};

export const searchForUuid = () => {
  return window.localStorage.getItem("uuid");
};

export const saveUuid = (uuid: string) => {
  window.localStorage.setItem("uuid", uuid);
};

export type Progress = {
  pageNo: number;
  data: unknown;
};
export const saveProgress = (uuid: string, progress: Progress) => {
  window.localStorage.setItem(uuid, JSON.stringify(progress));
};

export const getProgress = (uuid: string): Progress | null => {
  const progress = window.localStorage.getItem(uuid);

  //hack to get it quickly as Progress
  return progress !== null ? (JSON.parse(progress) as Progress) : progress;
};
