type AuthCacheConfig = {
  ticket: string;
};

export const AuthFlowCache = <T>(config: AuthCacheConfig) => {
  const authCacheKey = `pacellico-cache.${config.ticket}`;

  return {
    write: (item: T) => sessionStorage.setItem(authCacheKey, JSON.stringify(item)),
    read: (): T | undefined => {
      const serializedItem = sessionStorage.getItem(authCacheKey);

      if (!serializedItem) {
        return undefined;
      }
      return JSON.parse(serializedItem);
    },

    clear: () => sessionStorage.removeItem(authCacheKey),
  };
};
