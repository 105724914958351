import { env } from "./env";

export type ApiConfig = {
  BACKEND_URL: string;
  NEST_API_URL: string
};

export const API_CONFIG: ApiConfig = {
  BACKEND_URL: env("BACKEND_URL") ?? "nothing here",
  NEST_API_URL: env("NEST_API_URL") ?? "nothing here",
};
