import { ITokenCache } from "./cache/ITokenCache";
import { CacheManager, Seconds } from "../CacheManager/CacheManager";
import { InMemoryCache } from "../CacheManager/InMemmoryCache";

type TokenCacheManagerConfig = {
  ticket: string;
};

const TOKEN_TTL_REDUCER: Seconds = 10;

export const TokenCacheManager = (
  config: TokenCacheManagerConfig
): ITokenCache => {
  const cacheKey = `pacellico-cache.${config.ticket}`;
  const manager = new CacheManager(InMemoryCache());

  return {
    read: async (type) => {
      return await manager.get(`${cacheKey}.${type}`, TOKEN_TTL_REDUCER);
    },

    write: async (type, token, expiresIn) => {
      return await manager.set(`${cacheKey}.${type}`, token, expiresIn);
    },

    clear: async () => {
      return manager.clear();
    },
  };
};
