export type Options = {
    plugins: {
      legend: {
        display: boolean;
      };
    };
    scales: {
      r: {
        pointLabels: {
          font: {
            size: number;
          };
        };
        grid: {
          color: string[];
        };
        ticks: {
          display: boolean;
        };
        beginAtZero: boolean;
      };
    };
  }
  
  export const getOptions = (fontSize: number): Options => {
    return {
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        r: {
          pointLabels: {
            font: {
              size: fontSize,
            },
          },
          grid: {
            color: ["black"],
          },
          ticks: {
            display: false,
          },
          beginAtZero: true,
        },
      },
    };
  };
  
  export const getScreenBasedOptions = (): Options => {
    const screenWidth = window.innerWidth;
    let fontSize: number;
  
    if (screenWidth <= 480) {
      fontSize = 12;
    } else if (screenWidth <= 800) { 
      fontSize = 16;
    } else  { 
      fontSize = 20;
    }
    return getOptions(fontSize);
  };
  