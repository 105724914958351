import { ApiConfig } from "./api.config";
import { QuestionnaireConfig } from "./questionnaire.config";
import { AuthConfig } from "../plugins/auth/auth.config";

export type EnvVars = ApiConfig & QuestionnaireConfig & AuthConfig;

export function env(envVar: keyof EnvVars): EnvVars[keyof EnvVars] | undefined {
  if (window?.SERVER_DATA?.[envVar] !== undefined) {
    return window?.SERVER_DATA?.[envVar];
  }

  if (process.env[`REACT_APP_${envVar}`] !== undefined) {
    return process.env[`REACT_APP_${envVar}`] as EnvVars[keyof EnvVars];
  }

  return undefined;
}
