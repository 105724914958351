type Seconds = number;

export enum Token {
  AccessToken = 'access_token',
  RefreshToken = 'refresh_token',
}

export type ITokenCache = {
  write: (type: Token, token: string, expiresIn: Seconds) => Promise<void>;
  read: (type: Token) => Promise<string | undefined>;
  clear: () => Promise<void>;
};
