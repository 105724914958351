import React from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import { useGetQuestionsQuery } from "./repositories/GetQuestionsQuery";
import { SurveyModel } from "survey";
import { useNavigate } from "react-router-dom";
import CircularIndeterminate from "./components/atoms/loaders/LoaderComponent";
import { killProgress } from "./utils/progressTracking";
import { useTracking } from "./hooks/useTracking";
import { useSaveAnswerMutation } from "./repositories/SaveAnswerMutation";

import "animate.css";
import "./SurveyStyles.css";
import "./pacellico.css";
import { MenuItem, Select } from "@mui/material";

const SURVEY_ID = "survey-element-id";

type SurveyComponentProps = {
  onNewResponseUuid: (uuid: string) => void;
};

export const SurveyComponent = (props: SurveyComponentProps) => {
  const tracking = useTracking(props.onNewResponseUuid);
  const navigate = useNavigate();
  const [survey, setSurvey] = React.useState<Model | undefined>();
  const [pageNo, setPageNo] = React.useState<number>(0);

  const saveAnswer = useSaveAnswerMutation();

  const savePartialSurvey = (survey: SurveyModel) => {
    const data = survey.data;
    tracking.saveProgress({
      data,
      pageNo: survey.currentPageNo,
    });
  };

  let frozenData = {};
  let doAnimantion = true;

  const filterAndPush = async (survey: SurveyModel) => {
    let filteredData = {};

    Object.keys(survey.data).forEach((key) => {
      if (
        !(
          !frozenData[key] ||
          JSON.stringify(frozenData[key]) !== JSON.stringify(survey.data[key])
        )
      ) {
        return;
      }

      // let hackedForCheckboxMatrix = false;
      if (isMatrixCheckbox(survey.data[key])) {
        filteredData[key] = transformForBackend(survey.data[key]);

        return;
      }

      filteredData[key] = survey.data[key];
    });

    if (!isEmpty(filteredData)) {
      await saveAnswer.mutateAsync({
        response_guid: tracking.uuid,
        answer: filteredData,
      });
    }
  };

  const questionsQuery = useGetQuestionsQuery(tracking.uuid, {
    refetchOnWindowFocus: false,
    enabled: !tracking.isLoading,
    onError: (error: any) => {
      killProgress();
    },
    onSettled: (data) => {
      const freshSurvey = new Model(data?.questionnaire);
      freshSurvey.showCompletedPage = false;
      freshSurvey.sendResultOnPageNext = true;
      freshSurvey.showQuestionNumbers = false;

      freshSurvey.onCurrentPageChanged.add((_, options) => {
        setPageNo(options.newCurrentPage.visibleIndex);
      });

      freshSurvey.onComplete.add(async (survey, options) => {
        await filterAndPush(survey);
        killProgress();
        navigate(`/survey/complete/${tracking.uuid}`);
      });

      freshSurvey.onCurrentPageChanged.add(function (sender, options) {
        animate("fadeIn");
      });

      freshSurvey.onUpdateQuestionCssClasses.add(function (survey, options) {
        if (options.question.getType() === "matrix") {
          options.cssClasses.title += " survey--question__matrix--title";
        }
      });

      freshSurvey.onPartialSend.add(async (survey) => {
        await filterAndPush(survey);

        frozenData = survey.data;
        savePartialSurvey(survey);
      });

      freshSurvey.onCurrentPageChanging.add(function (sender, options) {
        if (!doAnimantion) {
          return;
        }

        options.allowChanging = false;
        const onAnimationEndHandler = () => {
          doAnimantion = false;

          sender.currentPage = options.newCurrentPage;

          doAnimantion = true;
        };

        animate(
          options.isPrevPage ? "fadeOutRight" : "fadeOutLeft",
          onAnimationEndHandler
        );
      });

      const progress = tracking.getProgress();

      if (progress) {
        freshSurvey.data = progress.data;
        if (progress.pageNo) {
          freshSurvey.currentPageNo = progress.pageNo;
        }
      }

      setSurvey(freshSurvey);
      setPageNo(freshSurvey.currentPageNo);
    },
  });

  const jumpToPage = (pageNo: number) => {
    if (survey?.currentPage.validate()) {
      setPageNo(pageNo);
    }
  };

  return (
    <>
      {questionsQuery.isLoading || tracking.isLoading || !Boolean(survey) ? (
        <div className="survey--loader--spinner">
          <CircularIndeterminate />
          Almost there!
        </div>
      ) : (
        <div className="survey-with-navigation">
          <div className="navigation-block">
            <Select
              value={pageNo}
              onChange={(evt) => {
                jumpToPage(Number(evt.target.value));
              }}
              sx={{
                width: 300,
              }}
            >
              {survey?.visiblePages.map((value, i) => (
                <MenuItem key={i} value={i}>
                  {value.title}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="survey-animation-wrapper">
            <div id={SURVEY_ID}>
              <Survey currentPageNo={pageNo} model={survey} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

function isEmpty(object: object): boolean {
  return Object.keys(object).length === 0;
}

function isMatrixCheckbox(data: unknown): boolean {
  if (!data || typeof data !== "object") {
    return false;
  }

  const childrenValidObjectsForMatrixCheckbox: boolean[] = [];

  Object.entries(data).forEach(([questionId, answers]) => {
    if (!answers || typeof answers !== "object") {
      childrenValidObjectsForMatrixCheckbox.push(false);
      return;
    }

    Object.entries(answers).forEach(([answerId, pickedAnswers]) => {
      childrenValidObjectsForMatrixCheckbox.push(
        Array.isArray(pickedAnswers) &&
          pickedAnswers.length === 1 &&
          pickedAnswers[0] === null
      );
    });
  });

  return childrenValidObjectsForMatrixCheckbox.reduce(
    (valid, result) => result && valid
  );
}

function transformForBackend(data: unknown): unknown {
  if (!data || typeof data !== "object") {
    return false;
  }

  const toReturn = Object.fromEntries(
    Object.entries(data).map(([questionId, answers]) => {
      return [questionId, Object.keys(answers)];
    })
  );

  return toReturn;
}

const animate = (animation: string, onEnd?: () => void) => {
  const surveyElement = document.getElementById(SURVEY_ID);
  if (!surveyElement) {
    return;
  }

  surveyElement.classList.add("animate__animated", `animate__${animation}`);

  const onEndHandler = () => {
    surveyElement.classList.remove(
      "animate__animated",
      `animate__${animation}`
    );
    onEnd?.();
    surveyElement.removeEventListener("animationend", onEndHandler);
  };
  surveyElement.addEventListener("animationend", onEndHandler);
};

export default SurveyComponent;
