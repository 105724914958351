import React from "react";
import { Outlet } from "react-router-dom";
import BrandHeader from "../../BrandHeader";
import BrandFooter from "../../BrandFooter";
import "../../SurveyStyles.css";

import "./Home.css";

export const Home = (): JSX.Element => {
  return (
    <div className="home">
      <div className="home__content">
        <BrandHeader />

        <Outlet />
      </div>
      <BrandFooter />
    </div>
  );
};
