import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

type SurveyComponentProps = {
  onNewResponseUuid: (uuid: string) => void;
};

export default function Authenticate(props: SurveyComponentProps) {
  const { uuid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (uuid) {
      props.onNewResponseUuid(uuid);
      navigate("/survey");
    }
  }, [navigate, props, uuid]);

  return (
    <>{uuid ? "launching authentication process..." : "checking identity..."}</>
  );
}
