import { useMutation, UseMutationOptions } from "react-query";
import { API_CONFIG } from "../config/api.config";

export type SaveGraphRequest = {
  response_guid: string;
  graph: string;
};

type SendReportParamsOptions = Omit<
  UseMutationOptions<void, unknown, SaveGraphRequest>,
  "mutationFn"
>;

export const useSaveGraphMutation = (options?: SendReportParamsOptions) => {
  return useMutation({
    ...options,
    mutationFn: async (graph: SaveGraphRequest) => {
      const url = new URL(`questionnaire`, API_CONFIG.BACKEND_URL);
      await fetch(url.href, {
        method: "post",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(graph),
      });

      return;
    },
  });
};
