import React from "react";
import { Button } from "../atoms/buttons/Button";
import { useNavigate } from "react-router-dom";
import { ContentCenterColumnBox } from "../atoms/layouts/ContentCenterColumnBox";

export const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <ContentCenterColumnBox>
      <h1>Sorry, page not found</h1>
      <Button size="xxl" onClick={() => navigate("/")}>
        Return to homepage
      </Button>
    </ContentCenterColumnBox>
  );
};
