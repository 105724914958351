import React from "react";
import { ReactComponent as PacellicoLogo } from "./pacellico_white.svg";
import "./BrandFooter.css";

export default function BrandFooter() {
  return (
    <div className="footer">
      <PacellicoLogo
        className="svgLogo"
        onClick={() => {
          window.location.href = "https://pacellico.com";
        }}
      />
      Copyright 2023
    </div>
  );
}
