import React from "react";
import { Checkbox as MuiCheckbox, FormControlLabel } from "@mui/material";

type CheckboxProps = {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label: string;
};

const Checkbox = ({ checked, onChange, label }: CheckboxProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          checked={checked}
          onChange={handleChange}
          color="primary"
        />
      }
      label={label}
    />
  );
};

export default Checkbox;
