import { createContext } from 'react';

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  sid: string;
};

export type AuthContextType = {
  user: User;
  isAuthenticated: boolean;
  logout: () => Promise<void>;
  getToken: () => Promise<string>;
};

export const DEFAULT_AUTH_CONTEXT = {
  getToken: () => {
    throw new Error('Not yet available');
  },
  isAuthenticated: false,
  logout: () => {
    throw new Error('Not yet available');
  },
  user: {
    email: 'unknown',
    firstName: 'unknown',
    lastName: 'unknown',
    id: 'unknown',
    sid: 'unknown',
  },
};
export const AuthContext = createContext<AuthContextType>(DEFAULT_AUTH_CONTEXT);
